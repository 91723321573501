<template>
  <div>
    <b-modal
      :no-close-on-backdrop="true"
      title="Transaction"
      v-model="showTransactionModal"
      @hide="resetTransactionForm"
      size="lg"
      hide-footer
    >
      <form @submit.prevent="saveTransaction">
        <b-row style="gap: 10px 0">
          <b-col cols="12">
            <label>Student</label>
            <v-select
              v-model="transactionForm.student_user_id"
              :options="students"
              label="user_name"
              :reduce="(item) => item.user_id"
              clearable
              :filterable="false"
              @search="onSearchChange"
            />
          </b-col>

          <b-col cols="12" v-if="services.length > 0">
            <label>Service</label>
            <v-select
              required
              v-model="transactionForm.service_id"
              clearable
              :options="services"
              label="service_name"
              :reduce="(item) => item.service_id"
              @input="
                () => {
                  transactionForm.plan_id = null;
                  handlePlanChange();
                }
              "
            />
          </b-col>

          <b-col cols="12" v-if="plans.length > 0">
            <label>Plan</label>
            <v-select
              required
              :disabled="transactionForm.service_id ? false : true"
              v-model="transactionForm.plan_id"
              clearable
              :options="
                plans.filter((p) => p.service_id === transactionForm.service_id)
              "
              label="plan_name"
              :reduce="(item) => item.plan_id"
              @input="handlePlanChange()"
            />
          </b-col>

          <b-col cols="12" v-if="plans.length > 0">
            <label>Remark</label>
            <b-form-input
              min="0"
              required
              type="text"
              v-model="transactionForm.remark"
            ></b-form-input>
          </b-col>

          <b-col cols="12">
            <label>Plan Amount</label>
            <b-form-input
              min="0"
              required
              :disabled="transactionForm.plan_id ? false : true"
              type="number"
              v-model="transactionForm.plan_amount"
              @input="handlePercentChange()"
            ></b-form-input>
          </b-col>

          <b-col cols="3">
            <label>Discount (%)</label>
            <b-form-input
              min="0"
              max="100"
              required
              type="number"
              v-model="transactionForm.discount_percent"
              @input="handlePercentChange()"
            ></b-form-input>
          </b-col>

          <b-col cols="9">
            <label>Discount Amount</label>
            <b-form-input
              min="0"
              step="0.01"
              required
              type="number"
              v-model="transactionForm.discount_amount"
            ></b-form-input>
          </b-col>

          <!-- <b-col cols="3">
            <label>Tax (%)</label>
            <b-form-input
              min="0"
              max="100"
              required
              type="number"
              v-model="transactionForm.tax_percent"
              @input="handlePercentChange()"
            ></b-form-input>
          </b-col>

          <b-col cols="9">
            <label>Tax Amount</label>
            <b-form-input
              min="0"
              required
              step="0.01"
              type="number"
              v-model="transactionForm.tax_amount"
            ></b-form-input>
          </b-col> -->

          <b-col cols="3" v-if="currencyCodes.data.length > 0">
            <label>Currency</label>
            <v-select
              v-model="transactionForm.currency"
              clearable
              :options="currencyCodes.data"
              label="code"
              :reduce="(item) => item.code"
              @input="handlePlanChange()"
            />
          </b-col>

          <b-col cols="9">
            <label>Final Amount</label>
            <b-form-input
              min="0"
              step="0.01"
              required
              type="number"
              v-model="transactionForm.transaction_amount"
            ></b-form-input>
          </b-col>

          <b-col
            cols="12"
            class="d-flex justify-content-end mt-2"
            style="gap: 10px"
          >
            <b-button @click="showTransactionModal = false" variant="danger"
              >Close</b-button
            >
            <b-button type="submit" variant="primary">Add</b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>

    <b-card title="Filters">
      <b-row style="gap: 10px 0">
        <b-col cols="3">
          <label>Student</label>
          <v-select
            v-model="filters.student_user_id"
            clearable
            :options="user_type === 'accounts' ? transaction_students : students"
            label="user_name"
            :reduce="(item) => item.user_id"
            @input="onSearch"
          />
        </b-col>
        <b-col cols="3">
          <label>Services</label>
          <v-select
            v-model="filters.service_id"
            clearable
            :options="services"
            label="service_name"
            :reduce="(item) => item.service_id"
            @input="onSearch"
          />
        </b-col>

        <b-col cols="3" v-if="filters.service_id">
          <label>Plans</label>
          <v-select
            v-model="filters.plan_id"
            clearable
            :options="plans.filter((p) => p.service_id === filters.service_id)"
            label="plan_name"
            :reduce="(item) => item.plan_id"
            @input="onSearch"
          />
        </b-col>

        <b-col cols="3">
          <label>Currency</label>
          <v-select
            v-model="filters.currency"
            clearable
            :options="currencyCodes.data"
            label="code"
            :reduce="(item) => item.code"
            @input="onSearch"
          />
        </b-col>

        <b-col cols="3">
          <label>Mode</label>
          <v-select
            v-model="filters.mode"
            :options="modes"
            :clearable="false"
            label="label"
            :reduce="(item) => item.value"
            @input="onSearch"
          />
        </b-col>

        <b-col cols="3">
          <label>Transaction Date</label>
          <flat-pickr
            v-model="filters.date_range"
            class="form-control"
            :config="{
              dateFormat: 'Y-m-d',
              altInput: true,
              altFormat: 'd-M-Y',
              allowInput: true,
              mode: 'range',
            }"
            @input="onSearch"
          />
        </b-col>

        <b-col cols="3">
          <label>Amount Range</label>
          <div class="d-flex align-items-center" style="gap: 5px">
            <b-form-input
              type="number"
              placeholder="From"
              v-model="filters.from_amount"
              @input="onSearch"
            />
            -
            <b-form-input
              type="number"
              placeholder="To"
              v-model="filters.to_amount"
              @input="onSearch"
            />
          </div>
        </b-col>

        <b-col
          cols="12"
          class="d-flex justify-content-end align-items-end"
          style="gap: 10px"
        >
          <!-- <b-button variant="primary" @click="getAllTransactions()">
            Apply
          </b-button> -->
          <b-button @click="resetFilters()" variant="danger"> Reset </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-card>
      <b-row class="justify-content-between my-1 px-1">
        <h2>Transactions</h2>
        <b-button
          v-if="user_type === 'accounts'"
          variant="primary"
          @click="showTransactionModal = true"
          >Add Transaction</b-button
        >
      </b-row>
      <b-table
        style="min-height: 500px"
        :fields="tableColumns"
        :items="transactions"
        :busy="isLoading"
        responsive
        show-empty
        empty-text="No Transactions Available"
      >
        <!-- Loader -->
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>

        <!-- Name -->
        <template #cell(student_name)="data">
          <div style="width: max-content">
            {{ data.item.student_firstname }} {{ data.item.student_lastname }}
          </div>
        </template>

        <!-- MODE -->
        <template #cell(is_online)="data">
          <div style="width: max-content">
            {{ data.item.is_online === "Y" ? "ONLINE" : "OFFLINE" }}
          </div>
        </template>

        <!-- REMARK  -->
        <template #cell(remark)="data">
          <div style="width: max-content" v-if="data.item.remark">
            {{ data.item.remark }}
          </div>
          <div style="width: max-content" v-else>-</div>
        </template>

        <template #cell(discount)="data">
          <div style="width: max-content" v-if="data.item.cc_flat_amount">
            {{ data.item.currency }} {{ data.item.cc_flat_amount }}
          </div>
          <!-- <div style="width: max-content" v-else-if="data.item.cc_percent">
            {{ data.item.cc_percent }} %
          </div> -->
          <div style="width: max-content" v-else-if="data.item.discount_amount">
            {{ data.item.discount_amount }}
          </div>
          <div style="width: max-content" v-else>0</div>
        </template>

        <template #cell(cc_code)="data">
          <div style="width: max-content" v-if="data.item.cc_code">
            {{ data.item.cc_code }}
          </div>
          <div style="width: max-content" v-else>-</div>
        </template>

        <!-- Transaction Status -->
        <template #cell(transaction_status)="data">
          <div style="width: max-content">
            <b-badge
              :variant="
                transaction_status_map[data.item.transaction_status].variant
              "
            >
              {{
                transaction_status_map[data.item.transaction_status].name
              }}</b-badge
            >
          </div>
        </template>

        <template #cell(sold_by)="data">
          <div v-if="user_type === 'counselor'">
            <v-select
            style="width: 175px;"
              v-model="data.item.sold_by"
              clearable
              :options="sold_by_options"
              label="label"
              :reduce="(item) => item.value"
              @input="saveSoldBy(data.item.transaction_id, data.item.sold_by)"
            />
          </div>
          <div v-else-if="data.item.sold_by" style="text-transform: capitalize">
            {{ data.item.sold_by }}
          </div>
          <div v-else>-</div>
        </template>

        <!-- MODE -->
        <template #cell(transaction_datetime)="data">
          <div style="width: max-content">
            {{
              moment(data.item.transaction_datetime).format(
                "DD MMM, YYYY - hh:mm A"
              )
            }}
          </div>
        </template>

        <template #cell(edit)="data" v-if="user_type === 'accounts'">
          <div style="width: max-content">
            <feather-icon
              v-if="data.item.is_online === 'N'"
              icon="EditIcon"
              class="cursor-pointer"
              @click="editTransaction(data.item.transaction_id)"
            />
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"> {{ total_transactions }} entries </span>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              aria-controls="StudentsListTableId"
              v-model="pagination.currentPage"
              :total-rows="total_transactions"
              :per-page="pagination.perPage"
              first-number
              last-number
              @change="onChange"
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BRow,
  BCol,
  BButton,
  BModal,
  BFormInput,
  BPagination,
  BSpinner,
  BBadge,
} from "bootstrap-vue";
import vSelect from "vue-select";
import CounselorServices from "@/apiServices/CounselorServices";
import CommonServices from "@/apiServices/CommonServices";
import HomeServices from "@/apiServices/HomeServices";
import currencyCodes from "currency-codes";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import AccountsServices from "@/apiServices/AccountsServices";
import store from "@/store";

export default {
  components: {
    BCard,
    BTable,
    BRow,
    BCol,
    BButton,
    BModal,
    BFormInput,
    vSelect,
    BPagination,
    BSpinner,
    FeatherIcon,
    BBadge,
    flatPickr,
  },
  computed: {
    user_type() {
      const userType = store.getters["user/getUserDetails"]?.user_type;
      if (userType) {
        if (userType === "accounts") {
          this.getTransactionStudents();
        }
        return userType;
      } else {
        return null;
      }
    },
  },
  watch: {
    user_type(val) {
      this.getAllTransactions();
    },
  },
  data() {
    return {
      moment,
      transactions: [],
      total_transactions: 0,
      filters: {
        student_user_id: null,
        service_id: null,
        plan_id: null,
        currency: "INR",
        mode: "all",
        date_range: null,
        from_amount: null,
        to_amount: null,
      },
      sold_by_options: [
        { label: "Counselor", value: "counselor" },
        { label: "Franchise", value: "franchise" },
      ],
      tableColumns: [
        {
          key: "transaction_id",
          label: "ID",
          sortable: false,
        },
        { key: "student_name", label: "Student Name", sortable: false },
        { key: "plan_name", label: "Plan Name", sortable: false },
        { key: "service_name", label: "Service Name", sortable: false },
        {
          key: "currency",
          label: "Currency",
          sortable: false,
        },
        {
          key: "plan_amount",
          label: "Plan Amount",
          sortable: false,
        },
        {
          key: "discount",
          label: "Discount Amount",
          sortable: false,
          variant: "success",
        },
        {
          key: "cc_code",
          label: "Coupon Code",
          sortable: false,
        },
        // {
        //   key: "discount_amount",
        //   label: "Discount",
        //   sortable: false,
        // },
        // {
        //   key: "tax_amount",
        //   label: "Tax",
        //   sortable: false,
        // },
        {
          key: "transaction_amount",
          label: "Final Amount",
          sortable: false,
          variant: "primary",
        },
        {
          key: "transaction_datetime",
          label: "Date",
          sortable: false,
        },
        {
          key: "transaction_status",
          label: "Status",
          sortable: false,
        },
        {
          key: "sold_by",
          label: "Sold By",
          sortable: false,
        },
        {
          key: "is_online",
          label: "Mode",
          sortable: false,
        },
        {
          key: "remark",
          label: "Remarks",
          sortable: false,
        },
      ],
      pagination: {
        currentPage: 1,
        perPage: 10,
        perPageOptions: [5, 10, 20, 30],
      },

      students: [],
      services: [],
      plans: [],
      currency: [],
      modes: [
        { label: "All", value: "all" },
        { label: "Online", value: "Y" },
        { label: "Offline", value: "N" },
      ],

      transaction_status_map: {
        pending: {
          name: "Pending",
          variant: "primary",
        },
        success: {
          name: "Success",
          variant: "success",
        },
        failed: {
          name: "Failed",
          variant: "danger",
        },
      },

      // Flags
      showTransactionModal: false,
      isLoading: false,

      // Form
      transactionForm: {
        transaction_id: null,
        student_user_id: null,
        service_id: null,
        plan_id: null,
        currency: "INR",
        remark: "",
        plan_amount: 0,
        transaction_amount: 0,
        discount_percent: 0,
        discount_amount: 0,
        tax_percent: 0,
        tax_amount: 0,
      },

      currencyCodes,
      debounce: null,
      search: "",
      sold_by: "",
      transaction_students: [],
    };
  },
  methods: {
    async getAllTransactions() {
      try {
        if (!this.user_type) return;

        this.isLoading = true;
        let res;
        if (this.user_type === "accounts") {
          this.tableColumns.push({
            key: "edit",
            label: "Edit",
            sortable: false,
          });
          res = await AccountsServices.getAllTransactions({
            ...this.filters,
            perPage: this.pagination.perPage,
            currentPage: this.pagination.currentPage,
          });

          if (res.data.status) {
            this.transactions = res.data.data.data;
            this.total_transactions = res.data.data.total;
            this.isLoading = false;
          }
        }

        if (this.user_type === "counselor") {
          res = await CounselorServices.getStudentTransactions({
            ...this.filters,
            perPage: this.pagination.perPage,
            currentPage: this.pagination.currentPage,
          });
          if (res.data.status) {
            this.isLoading = false;
            this.transactions = res.data.data.data;
            this.total_transactions = res.data.data.total;
          }
        }
      } catch (error) {
        console.log({ error });
      }
    },

    async getAllAssignedStudents() {
      try {
        const payload = {
          perPage: 10,
          currentPage: 1,
          search: this.search,
        };
        const res = await AccountsServices.getAssignedStudents(payload);
        if (res.data.status) {
          this.students = res.data.data.data;
        }
      } catch (error) {
        console.log({ error });
      }
    },

    async getTransactionStudents() {
      try {
        const res = await AccountsServices.getAllTransactionStudents();
        if (res.data.status) {
          this.transaction_students = res.data.data;
        }
      } catch (error) {
        console.log({ error });
      }
    },
    async saveSoldBy(transaction_id, sold_by) {
      try {
        const payload = {
          transaction_id,
          sold_by,
        };
        const res = await CounselorServices.saveSoldBy(payload);
        if (res.data.status) {
          this.onChange();
        }
      } catch (error) {
        console.log(error);
      }
    },
    onSearchChange(searchValue) {
      this.search = searchValue;
      this.getAllAssignedStudents();
    },
    async getCounselorAssignedStudents() {
      try {
        const res = await CommonServices.getAssignedStudents();
        if (res.data.status) {
          this.students = res.data.data.data;
        }
      } catch (error) {
        console.log({ error });
      }
    },

    async getAllServices() {
      try {
        const res = await HomeServices.getServicesAndPlans();
        if (res.data.status) {
          const servicesAndPlans = res.data.data;
          this.services = servicesAndPlans;
          servicesAndPlans.forEach((sp) => {
            this.plans = [...this.plans, ...sp.plans];
          });

          console.log({ services: this.services, plans: this.plans });
        }
      } catch (error) {
        console.log({ error });
      }
    },

    async saveTransaction() {
      const {
        student_user_id,
        service_id,
        plan_id,
        currency,
        transaction_amount,
      } = this.transactionForm;


      if (
        !student_user_id ||
        !service_id ||
        !plan_id ||
        !currency ||
        !transaction_amount
      ) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Please Add All Transaction Details",
            icon: "X",
            variant: "failure",
          },
        });

        return;
      }
      let res;
      try {
        res = await AccountsServices.saveStudentTransaction(
          this.transactionForm
        );

        if (res.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Transaction Saved Successfully",
              icon: "Check",
              variant: "success",
            },
          });

          this.getAllTransactions();
          this.showTransactionModal = false;
          return;
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: res.data.message,
              icon: "Check",
              variant: "success",
            },
          });
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: res.data.message,
            icon: "X",
            variant: "failure",
          },
        });

        console.log({ error });
      }
    },

    // Actions
    handlePlanChange() {
      const currentPlanId = this.transactionForm.plan_id;

      // this.transactionForm = {
      //   ...this.transactionForm,
      //   currency: "",
      //   remark: "",
      //   transaction_amount: 0,
      //   plan_amount: 0,
      //   discount_percent: 0,
      //   discount_amount: 0,
      //   tax_percent: 0,
      //   tax_amount: 0,
      // };

      if (!currentPlanId) return;

      const currentPlan = this.plans.find((p) => p.plan_id === currentPlanId);

      this.transactionForm = {
        ...this.transactionForm,
        // currency: currentPlan.plan_currency,
        transaction_amount:
          this.transactionForm.currency === "INR"
            ? currentPlan.pp_price
            : currentPlan.plan_price,
        plan_amount:
          this.transactionForm.currency === "INR"
            ? currentPlan.pp_price
            : currentPlan.plan_price,
      };
    },

    handlePercentChange() {
      const discountPercent = this.transactionForm.discount_percent;
      const taxPercent = this.transactionForm.tax_percent;
      const planAmount = this.transactionForm.plan_amount;

      let transactionAmount = planAmount;

      // Cut Discount
      const discountAmount = (planAmount * discountPercent) / 100;
      transactionAmount -= discountAmount;

      // Cut Tax
      const taxAmount = (transactionAmount * taxPercent) / 100;
      transactionAmount -= taxAmount;

      this.transactionForm.discount_amount = discountAmount.toFixed(2);
      this.transactionForm.tax_amount = taxAmount.toFixed(2);
      this.transactionForm.transaction_amount = transactionAmount.toFixed(2);
    },

    editTransaction(id) {
      const transaction = this.transactions.find(
        (t) => t.transaction_id === id
      );

      const service_id =
        this.services.find((s) =>
          s.plans.map((p) => p.plan_id).includes(transaction.plan_id)
        )?.service_id || null;

      this.transactionForm = {
        student_user_id: transaction.student_user_id,
        transaction_id: transaction.transaction_id,
        service_id,
        plan_id: service_id ? transaction.plan_id : null,
        currency: transaction.currency,
        transaction_amount: transaction.transaction_amount,
        plan_amount: transaction.plan_amount,
        tax_percent: transaction.tax_percent,
        tax_amount: transaction.tax_amount,
        discount_percent: transaction.discount_percent,
        discount_amount: transaction.discount_amount,
        remark: transaction.remark,
      };
      this.showTransactionModal = true;
    },

    resetTransactionForm() {
      this.transactionForm = {
        transaction_id: null,
        student_user_id: null,
        service_id: null,
        plan_id: null,
        currency: "INR",
        transaction_amount: 0,
        plan_amount: 0,
        discount_percent: 0,
        discount_amount: 0,
        tax_percent: 0,
        tax_amount: 0,
        remark: "",
      };
    },

    resetFilters() {
      this.filters = {
        student_user_id: null,
        service_id: null,
        plan_id: null,
        currency: "INR",
        mode: "all",
        date_range: null,
        from_amount: null,
        to_amount: null,
      };

      this.getAllTransactions();
    },
    onChange() {
      this.$nextTick(() => {
        this.getAllTransactions();
      });
    },
    onSearch() {
      this.pagination.currentPage = 1;
      if (this.debounce) {
        clearTimeout(this.debouce);
        this.debounce = null;
      }
      this.debounce = setTimeout(() => {
        this.onChange();
      }, 1000);
    },
  },
  watch: {},
  async beforeMount() {
    console.log("User", this.user_type);
    if (["accounts"].includes(this.user_type)) {
      this.getAllAssignedStudents();
      this.getTransactionStudents();
      // this.getAllTra;
    } else {
      this.getCounselorAssignedStudents();
    }
    await this.getAllServices();
    this.getAllTransactions();
  },
};
</script>
